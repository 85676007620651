import React from "react";
import { IconProps } from "../icon";

export const Shuffle_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18 15l3 3m0 0l-3 3m3-3h-2.431c-.94 0-1.409 0-1.835-.13a2.998 2.998 0 01-1.033-.552c-.345-.283-.605-.674-1.126-1.455l-.242-.363M18 3l3 3m0 0l-3 3m3-3h-2.431c-.94 0-1.409 0-1.835.13a3 3 0 00-1.033.552c-.345.283-.605.674-1.126 1.455l-5.15 7.726c-.521.781-.782 1.172-1.126 1.455-.304.25-.655.438-1.033.552-.426.13-.896.13-1.835.13H3M3 6h2.431c.94 0 1.409 0 1.835.13a3 3 0 011.033.552c.344.283.605.674 1.126 1.455l.242.363"
    />
  </svg>
);
