import React from "react";
import { IconProps } from "../icon";

export const Microphone_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 12v1a8 8 0 11-16 0v-1m8 5a4 4 0 01-4-4V7a4 4 0 118 0v6a4 4 0 01-4 4z"
    />
  </svg>
);
