import React from "react";
import { IconProps } from "../icon";

export const VideoRecorderOffIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 5a3 3 0 00-3 3v8a3 3 0 003 3h9a3.001 3.001 0 002.87-2.125M17 12l3.634-3.634c.429-.429.643-.643.827-.657a.5.5 0 01.42.173c.119.14.119.444.119 1.05v6.137c0 .605 0 .908-.12 1.049a.5.5 0 01-.42.173c-.183-.014-.397-.228-.826-.657L17 12zm0 0V9.8c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C14.72 5 13.88 5 12.2 5H9.5M2 2l20 20"
    />
  </svg>
);
