import React from "react";
import { IconProps } from "../icon";

export const AiIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M3 6a2 2 0 012-2h14a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V6zm12 2a1 1 0 112 0v8a1 1 0 11-2 0V8zM9.5 7A3.5 3.5 0 006 10.5V16a1 1 0 102 0v-2h3v2a1 1 0 102 0v-5.5A3.5 3.5 0 009.5 7zm0 2A1.5 1.5 0 008 10.5V12h3v-1.5A1.5 1.5 0 009.5 9z"
      clipRule="evenodd"
    />
  </svg>
);
