import React from "react";
import { IconProps } from "../icon";

export const Lightbulb_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 16.5V19c0 .932 0 1.398-.152 1.765a2 2 0 01-1.083 1.083C13.398 22 12.932 22 12 22c-.932 0-1.398 0-1.765-.152a2 2 0 01-1.083-1.083C9 20.398 9 19.932 9 19v-2.5m6 0c2.649-1.157 4.5-3.925 4.5-7a7.5 7.5 0 00-15 0c0 3.075 1.851 5.843 4.5 7m6 0H9"
    />
  </svg>
);
