import React from "react";
import { IconProps } from "../icon";

export const MusicIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.5 18V5.589c0-.857 0-1.286.18-1.544a1 1 0 01.674-.416c.312-.046.695.145 1.462.529L20.5 6m-6 12a3 3 0 11-6 0 3 3 0 016 0zm-8-8V4m-3 3h6"
    />
  </svg>
);
