import React from "react";
import { IconProps } from "../icon";

export const Microphone_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 10v2a7 7 0 01-7 7m-7-9v2a7 7 0 007 7m0 0v3m-4 0h8m-4-7a3 3 0 01-3-3V5a3 3 0 116 0v7a3 3 0 01-3 3z"
    />
  </svg>
);
