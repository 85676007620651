import React from "react";
import { IconProps } from "../icon";

export const Webcam_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 18a8 8 0 100-16 8 8 0 000 16zm0 0v4m0 0H7m5 0h5m-2-12a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);
