import React from "react";
import { IconProps } from "../icon";

export const MicrophoneOff_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 12v1a8 8 0 0014.138 5.132M2 2l20 20m-6-11.6V7a4 4 0 00-6.53-3.1M12 17a4 4 0 01-4-4V8l7.281 7.288A3.995 3.995 0 0112 17z"
    />
  </svg>
);
