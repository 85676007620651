import React from "react";
import { IconProps } from "../icon";

export const BluetoothSignalIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 7l12 10-6 5V2l6 5L2 17M20.145 6.5a9.386 9.386 0 011.769 5.5 9.386 9.386 0 01-1.77 5.5M17 8.857c.621.891.986 1.975.986 3.143A5.475 5.475 0 0117 15.143"
    />
  </svg>
);
