import React from "react";
import { IconProps } from "../icon";

export const Speaker_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 12h.01M18 6h.01M6 6h.01M18 18h.01M6 18h.01m.79 4h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C22 19.72 22 18.88 22 17.2V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C19.72 2 18.88 2 17.2 2H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C2 4.28 2 5.12 2 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C4.28 22 5.12 22 6.8 22zm5.7-10a.5.5 0 11-1 0 .5.5 0 011 0zm4.5 0a5 5 0 11-10 0 5 5 0 0110 0z"
    />
  </svg>
);
