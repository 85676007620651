import React from "react";
import { IconProps } from "../icon";

export const MicrophoneOff_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 9.4V5a3 3 0 00-5.688-1.334M12 19v3m0-3a7 7 0 01-7-7v-2m7 9a7 7 0 007-7v-2M8 22h8M2 2l20 20m-10-7a3 3 0 01-3-3V9l5.123 5.12A2.99 2.99 0 0112 15z"
    />
  </svg>
);
