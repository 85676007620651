import React from "react";
import { IconProps } from "../icon";

export const ChromeCastIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 12.05A9 9 0 019.95 20M2 16.1A5 5 0 015.9 20M2 20h.01M14 21h3.2c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C22 18.72 22 17.88 22 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C19.72 3 18.88 3 17.2 3H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C2 5.28 2 6.12 2 7.8V8"
    />
  </svg>
);
