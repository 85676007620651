import React from "react";
import { IconProps } from "../icon";

export const Lightbulb_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 17.659V20a2 2 0 104 0v-2.341M12 2v1m-9 9H2m3.5-6.5l-.6-.6m13.6.6l.6-.6M22 12h-1m-3 0a6 6 0 11-12 0 6 6 0 0112 0z"
    />
  </svg>
);
