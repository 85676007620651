import React from "react";
import { IconProps } from "../icon";

export const BluetoothIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 17l6-5v10l5.44-4.533M12 7V2l6 5-2.918 2.432M21 21L3 3"
    />
  </svg>
);
