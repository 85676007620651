import React from "react";
import { IconProps } from "../icon";

export const Webcam_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 22h8m4.5-11.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zm-5.313 0a3.188 3.188 0 11-6.375 0 3.188 3.188 0 016.376 0z"
    />
  </svg>
);
