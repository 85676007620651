import React from "react";
import { IconProps } from "../icon";

export const Shuffle_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 16v5m0 0h-5m5 0l-6-6M3 3l6 6m7-6h5m0 0v5m0-5L3 21"
    />
  </svg>
);
