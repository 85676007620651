import React from "react";
import { IconProps } from "../icon";

export const VideoRecorderIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 8.931c0-.605 0-.908-.12-1.049a.5.5 0 00-.42-.173c-.183.014-.397.228-.826.657L17 12l3.634 3.634c.429.429.643.643.827.657a.5.5 0 00.42-.173c.119-.14.119-.444.119-1.05V8.932zM2 9.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C4.28 5 5.12 5 6.8 5h5.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C17 7.28 17 8.12 17 9.8v4.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C14.72 19 13.88 19 12.2 19H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C2 16.72 2 15.88 2 14.2V9.8z"
    />
  </svg>
);
