import React from "react";
import { IconProps } from "../icon";

export const Disc_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 5.674A7 7 0 0119 12M8.392 18A6.996 6.996 0 015 12m17 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-7 0a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);
