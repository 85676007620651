import React from "react";
import { IconProps } from "../icon";

export const MouseIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9V6m0 16a7 7 0 01-7-7V9a7 7 0 0114 0v6a7 7 0 01-7 7z"
    />
  </svg>
);
