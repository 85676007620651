import React from "react";
import { IconProps } from "../icon";

export const AirpodsIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 7.625a4.125 4.125 0 004.125 4.125c.306 0 .459 0 .538.027a.445.445 0 01.31.31c.027.08.027.203.027.452v6.336a1.625 1.625 0 103.25 0V7.625a4.125 4.125 0 00-8.25 0zM22 7.625a4.125 4.125 0 01-4.125 4.125c-.306 0-.459 0-.538.027a.445.445 0 00-.31.31c-.027.08-.027.203-.027.452v6.336a1.625 1.625 0 11-3.25 0V7.625a4.125 4.125 0 118.25 0z"
    />
  </svg>
);
