import React from "react";
import { IconProps } from "../icon";

export const Repeat_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 20.5a8.5 8.5 0 005-15.375M13 22.4l-2-2 2-2M12 3.5a8.5 8.5 0 00-5 15.375M11 5.6l2-2-2-2"
    />
  </svg>
);
