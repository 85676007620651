import React from "react";
import { IconProps } from "../icon";

export const BluetoothConnectIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 7l12 10-6 5V2l6 5L3 17m15-5h.01M15 12h.01M21 12h.01"
    />
  </svg>
);
