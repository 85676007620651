import React from "react";
import { IconProps } from "../icon";

export const Headphones_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 17v-4c0-5.523-4.477-10-10-10S2 7.477 2 13v4m5.5 4A2.5 2.5 0 015 18.5v-3a2.5 2.5 0 015 0v3A2.5 2.5 0 017.5 21zm9 0a2.5 2.5 0 01-2.5-2.5v-3a2.5 2.5 0 015 0v3a2.5 2.5 0 01-2.5 2.5z"
    />
  </svg>
);
