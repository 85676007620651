import React from "react";
import { IconProps } from "../icon";

export const Repeat_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 22l-3-3m0 0l3-3m-3 3h5a7 7 0 003-13.326M6 18.326A7 7 0 019 5h5m0 0l-3-3m3 3l-3 3"
    />
  </svg>
);
